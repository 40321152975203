import { GET_FOOTER, UPDATE_FOOTER } from "../actions/types";

const initialState = {
  footer: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_FOOTER:
      return {
        ...state,
        footer: payload,
      };
    case UPDATE_FOOTER:
      return {
        ...state,
        footer: payload,
      };
    default:
      return state;
  }
}
