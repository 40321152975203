import {
  ADD_SERVICES,
  GET_OUR_SERVICES,
  DELETE_SERVICE,
  GET_SERVICES_PAGE,
  UPDATE_TEXT_SERVICE,
  UPDATE_SERVICES_VIDEO,
  PENDING_REQUEST,
  PENDING_FAILED,
  UPDATE_SINGLE_SERVICE,
} from "./../actions/types";

const initialState = {
  services: [],
  servicesPage: [],
  pending: false,
  pendingFinished: false,
  error: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_OUR_SERVICES:
      return {
        ...state,
        services: payload,
      };
    case ADD_SERVICES:
      return {
        ...state,
        services: payload,
      };
    case DELETE_SERVICE:
      return {
        ...state,
        services: state.services.filter((el) => el._id !== payload),
      };
    case GET_SERVICES_PAGE:
      return {
        ...state,
        servicesPage: payload,
      };
    case UPDATE_TEXT_SERVICE:
      return {
        ...state,
        servicesPage: payload,
      };
    case UPDATE_SERVICES_VIDEO:
      return {
        ...state,
        servicesPage: payload,
        pending: false,
        pendingFinished: true,
        error: false,
      };
    case UPDATE_SINGLE_SERVICE:
      return {
        ...state,
        services: payload,
      };
    case PENDING_REQUEST:
      return {
        ...state,
        pending: true,
        error: false,
        pendingFinished: false,
      };
    case PENDING_FAILED:
      return {
        ...state,
        pending: false,
        pendingFinished: true,
        error: true,
      };
    default:
      return state;
  }
}
