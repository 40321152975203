import { POST_LOGIN, POST_LOGOUT } from "./../actions/types";

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  error: null,
  status: "",
  
};

export default function (state = initialState, action) {
  switch (action.type) {
    case POST_LOGIN:
      
      localStorage.setItem("token", action.payload);

      return {
        ...state,
       token: action.payload, 
        isAuthenticated: true,
        status: "sucesss",
      };
    case "POST_LOGIN_FAILED":
      return {
        ...state,
        auth: action.payload,
        status: "error",
      };
      case  POST_LOGOUT : 
      return {
        
        ...state,
       token: null,
       isAuthenticated: false
      } 
    default:
      return state;
  }
}
