import {
  GET_HOME,
  UPDATE_VIDEO,
  UPDATE_TEXT,
  GET_LATEST_WORKS,
  DELETE_WORK,
  GET_TAGS,
  ADD_WORK,
  GET_CLIENTS,
  DELETE_CLIENT,
  ADD_CLIENT,
  DELETE_TAG,
  ADD_TAG,
  PENDING_REQUEST,
  PENDING_FAILED,
  UPDATE_SINGLE_WORK,
  GET_MOBILE_TEXT,
  UPDATE_MOBILE_TEXT,
  UPDATE_WORKS_ORDER,
} from "../actions/types";

const initialState = {
  home: [],
  latestWorks: [],
  tags: [],
  clients: [],
  pending: false,
  pendingFinished: false,
  error: false,
  mobileText: [],
  orderUpdated: false,
};

export default function (state = initialState, action) {
  
  const { type, payload } = action;
  switch (type) {
    case GET_HOME:
      return {
        ...state,
        home: payload,
        orderUpdated: false,
      };
    case PENDING_REQUEST:
      return {
        ...state,
        pending: true,
        error: false,
        pendingFinished: false,
      };
    case GET_MOBILE_TEXT:
      return {
        ...state,
        mobileText: payload,
      };
    case UPDATE_MOBILE_TEXT:
      return {
        ...state,
        mobileText: payload,
      };
    case UPDATE_VIDEO:
      return {
        ...state,
        home: payload,
        pending: false,
        pendingFinished: true,
        error: false,
      };
    case PENDING_FAILED:
      return {
        ...state,
        pending: false,
        pendingFinished: true,
        error: true,
      };
    case UPDATE_TEXT:
      return {
        ...state,
        home: payload,
      };
    case GET_LATEST_WORKS:
      return {
        ...state,
        latestWorks: payload,
        orderUpdated: false,
      };
    case DELETE_WORK:
      return {
        ...state,
        latestWorks: state.latestWorks.filter((work) => work._id !== payload),
      };

    case GET_TAGS:
      return {
        ...state,
        tags: payload,
      };
    case DELETE_TAG:
      return {
        ...state,
        tags: state.tags.filter((tag) => tag._id !== payload),
      };

    case ADD_WORK:
      return {
        ...state,
        latestWorks: payload,
      };
    case UPDATE_SINGLE_WORK:
      return {
        ...state,
        latestWorks: payload,
      };
    case GET_CLIENTS:
      return {
        ...state,
        clients: payload,
      };
    case DELETE_CLIENT:
      return {
        ...state,
        clients: state.clients.filter((client) => client._id !== payload),
      };
    case ADD_CLIENT:
      return {
        ...state,
        clients: payload,
      };
    case ADD_TAG:
      return {
        ...state,
        tags: payload,
      };
    case UPDATE_WORKS_ORDER:
      return {
        ...state,
        orderUpdated: true,
      };
    default:
      return state;
  }
}
