import {
  GET_OUR_WORKS,
  UPDATE_OUR_WORKS_TEXT,
  UPDATE_OUR_WORKS_VIDEO,
  PENDING_REQUEST,
  PENDING_FAILED,
} from "./../actions/types";

const initialState = {
  ourWorks: [],
  pending: false,
  pendingFinished: false,
  error: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_OUR_WORKS:
      return {
        ...state,
        ourWorks: payload,
      };
    case UPDATE_OUR_WORKS_TEXT:
      return {
        ...state,
        ourWorks: payload,
      };
    case PENDING_REQUEST:
      return {
        ...state,
        pending: true,
        error: false,
        pendingFinished: false,
      };
    case UPDATE_OUR_WORKS_VIDEO:
      return {
        ...state,
        ourWorks: payload,
        pending: false,
        pendingFinished: true,
        error: false,
      };
    case PENDING_FAILED:
      return {
        ...state,
        pending: false,
        pendingFinished: true,
        error: true,
      };

    default:
      return state;
  }
}
