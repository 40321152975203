export const POST_LOGIN = "POST_LOGIN";
export const POST_LOGOUT = "POST_LOGOUT";
export const GET_HOME = "GET_HOME";
export const UPDATE_TEXT = "UPDATE_TEXT";
export const UPDATE_VIDEO = "UPDATE_VIDEO";
export const GET_LATEST_WORKS = "GET_LATEST_WORKS";
export const DELETE_WORK = "DELETE_WORK";
export const GET_TAGS = "GET_TAGS";
export const ADD_WORK = "ADD_WORK";
export const GET_OUR_SERVICES = "GET_OUR_SERVICES";
export const ADD_SERVICES = "ADD_SERVICES";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const GET_CLIENTS = "GET_CLIENTS";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const ADD_CLIENT = "ADD_CLIENT";
export const GET_SERVICES_PAGE = "GET_SERVICES_PAGE";
export const UPDATE_TEXT_SERVICE = "UPDATE_TEXT_SERVICE";
export const UPDATE_SERVICES_VIDEO = "UPDATE_SERVICES_VIDEO";
export const GET_OUR_WORKS = "GET_OUR_WORKS";
export const UPDATE_OUR_WORKS_TEXT = "UPDATE_OUR_WORKS_TEXT";
export const UPDATE_OUR_WORKS_VIDEO = "UPDATE_OUR_WORKS_VIDEO";
export const DELETE_TAG = "DELETE_TAG";
export const ADD_TAG = "ADD_TAG";
export const GET_MEDIA_BUYING = "GET_MEDIA_BUYING";
export const UPDATE_MEDIA_BUYING_TEXT = "UPDATE_MEDIA_BUYING_TEXT";
export const UPDATE_MEDIA_BUYING_VIDEO = "UPDATE_MEDIA_BUYING_VIDEO";
export const GET_NETWORKS = "GET_NETWORKS";
export const DELETE_NETWORK = "DELETE_NETWORK";
export const ADD_NETWORK = "ADD_NETWORK";
export const GET_ABOUT = "GET_ABOUT";
export const UPDATE_IMG = "UPDATE_IMG";
export const UPDATE_ABOUT_TEXT = "UPDATE_ABOUT_TEXT";
export const GET_TESTEMONIES = "GET_TESTEMONIES";
export const DELETE_TESTEMONY = "DELETE_TESTEMONY";
export const ADD_TESTEMONY = "ADD_TESTEMONY";
export const PENDING_REQUEST = "PENDING_REQUEST";
export const PENDING_FAILED = "PENDING_FAILED";
export const GET_REQUESTS = "GET_REQUESTS";
export const UPDATE_SINGLE_WORK = "UPDATE_SINGLE_WORK";
export const UPDATE_SINGLE_SERVICE = "UPDATE_SINGLE_SERVICE";
export const UPDATE_SINGLE_TESTEMONY = "UPDATE_SINGLE_TESTEMONY";
export const GET_FOOTER = "GET_FOOTER";
export const UPDATE_FOOTER = "UPDATE_FOOTER";
export const GET_MOBILE_TEXT = "GET_MOBILE_TEXT";
export const UPDATE_MOBILE_TEXT = "UPDATE_MOBILE_TEXT";
export const UPDATE_WORKS_ORDER = "UPDATE_WORKS_ORDER";
