import {
  GET_MEDIA_BUYING,
  UPDATE_MEDIA_BUYING_TEXT,
  UPDATE_MEDIA_BUYING_VIDEO,
  GET_NETWORKS,
  DELETE_NETWORK,
  ADD_NETWORK,
  PENDING_REQUEST,
  PENDING_FAILED,
} from "./../actions/types";

const initialState = {
  mediaBuying: [],
  networks: [],
  pending: false,
  pendingFinished: false,
  error: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_MEDIA_BUYING:
      return {
        ...state,
        mediaBuying: payload,
      };
    case UPDATE_MEDIA_BUYING_TEXT:
      return {
        ...state,
        mediaBuying: payload,
      };
    case UPDATE_MEDIA_BUYING_VIDEO:
      return {
        ...state,
        mediaBuying: payload,
        pending: false,
        pendingFinished: true,
        error: false,
      };
    case PENDING_REQUEST:
      return {
        ...state,
        pending: true,
        error: false,
        pendingFinished: false,
      };
    case PENDING_FAILED:
      return {
        ...state,
        pending: false,
        pendingFinished: true,
        error: true,
      };
    case GET_NETWORKS:
      return {
        ...state,
        networks: payload,
      };
    case DELETE_NETWORK:
      return {
        ...state,
        networks: state.networks.filter((net) => net._id !== payload),
      };
    case ADD_NETWORK:
      return {
        ...state,
        networks: payload,
      };
    default:
      return state;
  }
}
