import {
  GET_ABOUT,
  UPDATE_IMG,
  UPDATE_ABOUT_TEXT,
  GET_TESTEMONIES,
  DELETE_TESTEMONY,
  ADD_TESTEMONY,
  UPDATE_SINGLE_TESTEMONY,
} from "./../actions/types";

const initialState = {
  about: [],
  testemonies: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ABOUT:
      return {
        ...state,
        about: payload,
      };
    case UPDATE_IMG:
      return {
        ...state,
        about: payload,
      };
    case UPDATE_ABOUT_TEXT:
      return {
        ...state,
        about: payload,
      };

    case GET_TESTEMONIES:
      return {
        ...state,
        testemonies: payload,
      };
    case DELETE_TESTEMONY:
      return {
        ...state,
        testemonies: state.testemonies.filter((el) => el._id !== payload),
      };
    case ADD_TESTEMONY:
      return {
        ...state,
        testemonies: payload,
      };
    case UPDATE_SINGLE_TESTEMONY:
      return {
        ...state,
        testemonies: payload,
      };

    default:
      return state;
  }
}
