import { combineReducers } from "redux";
import authReducer from "./authReducer";
import homeReducer from "./homeReducer";
import servicesReducer from "./servicesReducer";
import ourWorksReducer from "./ourWorksReducer";
import mediaBuyingReducer from "./mediaBuyingReducer";
import aboutReducer from "./aboutReducer";
import requestsReducer from "./requestsReducer";
import footerReducer from "./footerReducer";

export default combineReducers({
  auth: authReducer,
  home: homeReducer,
  services: servicesReducer,
  ourWorks: ourWorksReducer,
  mediaBuying: mediaBuyingReducer,
  about: aboutReducer,
  requests: requestsReducer,
  footer: footerReducer,
});
